import React, {useEffect, useState} from 'react';
import './ChatBlock.scss';
import {OctopusIcon} from "../../image";
import {Popover, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import Chat from "./components/Chat/Chat";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {history} from "../../utils/history";
import {ROUTERS_MAP} from "../../utils/routes";
import UserInfoModal from "../../pages/Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import {isCheckAuth} from "../../utils/helperFunctions";


const ChatBlock = (props) => {
	const {t} = useTranslation();
	const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const [showPersonsModal, setShowPersonsModal] = useState(false)
	const [currentUser, setCurrentUser] = useState(null);
	const userProfile = useSelector(state => state.profile.userProfile);
	const checkVideoPage = (location.pathname || '').includes('/dashboard/videos/video');
	const isAuth = localStorage.getItem('tooltipChat');

	function openUserModal(data) {
		if(userProfile?.attached_person?.id === data.id){
			history.push(ROUTERS_MAP.PROFILE);
		}else{
			setShowPersonsModal(true)
			setCurrentUser(data)
		}
	}
	function closeModal() {
		setShowPersonsModal(false)
	}

	const hidePopover = () => {
		setOpen(false);
	};

	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	useEffect(()=>{
		if(isAuth){
			setIsVisibleTooltip(true)
			setTimeout(() =>{
				setIsVisibleTooltip(false);
				localStorage.removeItem('tooltipChat')
			},5000)
		}
	},[])



	return (
		<div
			className="chat-block"
			onWheel={(e) => e.stopPropagation()}
		>
			<Popover
				arrow={false}
				trigger='click'
				placement="rightTop"
				content={<Chat hidePopover={hidePopover} openUserModal={openUserModal} isOpenPopover={open}/>}
				overlayClassName="chat-block__popover"
				destroyTooltipOnHide={true}
				onOpenChange={handleOpenChange}
				open={open}
			>
				<Tooltip
					open={isVisibleTooltip}
					placement="left"
					overlayClassName="chat-block__tooltip"
					title={
						<div className={'chat-block__tooltip--wrap'}>
							<span className="chat-block__tooltip--title">{t('chat_main_tooltip_title')}</span>
							<span className="chat-block__tooltip--text">{t('chat_main_tooltip_text')}</span>
						</div>
					}
				>
					<div
						className="badge"
						onMouseEnter={() => setIsVisibleTooltip(true)}
						onMouseLeave={() => setIsVisibleTooltip(false)}
						onClick={() => {
							setIsVisibleTooltip(false);
						}}
					>
						<div className="badge__left"/>
						<div className="badge__right">
							<OctopusIcon/>
						</div>
					</div>
				</Tooltip>
			</Popover>
			{showPersonsModal && <UserInfoModal show={showPersonsModal} closeModal={closeModal} user={currentUser}/>}
		</div>
	);
};

export default ChatBlock;