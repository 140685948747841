// Import packages
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Nestable from "react-nestable";
import {useLocation} from "react-router-dom";
import {connect, useSelector} from "react-redux";

// Import assets
import './protocol.scss';
import {CloseIcon, DeletingIcon, EditIcon, EllipsisIcon, PlusIcon, ShareIcon, VoiceMessage} from "../../../../../image";

//Import utils
import {getPropsFromState} from "../../../../../redux/mapStateToProps";
import {
    AddProtocolHighlight,
    DeleteProtocolHighlight,
    UpdateProtocolPosition,
    GetProtocol
} from "../../../../../redux/actions";
import {getAuthorName, parseTime} from "../../../../../utils/helperFunctions";

//Import components
import ProtocolItem from "./ProtocolItem/ProtocolItem";
import {useTranslation} from "react-i18next";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Tooltip} from "react-bootstrap";
import AutocompleteUsers from "../../../../../shared/features/autocomplete/AutocompleteUsers";
import {Text} from "../../../../../modules";
import {Popover} from "antd";
import {ProtocolItemBlock} from "../../../../../modules/Protocol";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";
import {Draggable} from "../../../../../modules/Protocol/components/DndProtocolList/Draggable";
import {SHOW_ADD_FORM_TYPES} from "../../../../../modules/Protocol/helpers";

const initProtocolData = {
    person_id: '',
    author_name: '',
    text: '',
    time: '',
    task_executor_person_id: '',
    task_executor_name: ''
}

function Protocol(props) {
    const {
        AddProtocol,
        UpdateProtocol,
        UpdateProtocolPosition,
        DeleteProtocol,
        GetLink,
        protocolList,
        videoPersons,
        changeVideoTime, videoPlayerCurrentTime, getTime,
        videoId, company,
        token,
        userProfile,
        personsList,
        MarkVideo,
        texts,
        setCopied,
        currentVideoId,
        page
    } = props

    const {t, i18n} = useTranslation();
    const [protocolData, setProtocolData] = useState(initProtocolData)
    const [showFirstForm, setShowFirstForm] = useState(false)

    const [error, setError] = useState({
        person_id: false,
        author_name: false,
        text: false,
        time: false
    })
    const [persons, setPersons] = useState([])

    let disabledBtn = !!protocolData.text?.length
    const location = useLocation()
    const protocolContent = useRef();
    const profileInfo = useSelector(state => state.profile?.userProfile)

    // useEffect(() => {
    //     if (copied) {
    //         setTimeout(() => {
    //             setCopied(false)
    //         }, 300)
    //     }
    // }, [copied])

    useEffect(() => {
        setProtocolData({
            ...protocolData,
            author_name: `${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`
        })

        !!personsList?.results?.length && (setPersons([...videoPersons, ...personsList?.results?.filter(
            i => !videoPersons?.find(person => person?.id === i?.id)
        )]))
    }, [userProfile])

    const handleScroll = (position) => {
        protocolContent?.current && protocolContent.current.scrollTo({
            top: position
        });
        protocolContent?.current && protocolContent.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    };

    const scrollToBottom = () => {
        if (protocolContent.current) {
            protocolContent.current.scrollTo({
                top: 99999, // Скроллим до конца
                behavior: 'smooth', // Плавная прокрутка
            });
        }
    };


    const addVideoProtocol = (text, index) => {
        const {time, author_name, person_id, task_executor_name, task_executor_person_id} = protocolData
        let start_time = parseTime(time)
        let result = true
        let errors = {}

        if (!text) {
            errors.text = true
            result = false
        }

        setError(errors)
        if (result) {
            let reqData = {
                text,
            }
            // time && (reqData.start_time = start_time || 0)
            videoId && (reqData.video_id = videoId)
            const lastIndex = protocolList.length || 0;
            const lastId = lastIndex ? protocolList[lastIndex - 1]?.id : null;
            lastId && (reqData.insert_after_protocol_block_id = lastId)

            // task_executor_person_id && (reqData.task_executor_person_id = task_executor_person_id)
            // if (!person_id && author_name) {
            //     reqData.author_name = author_name ? author_name : null
            // }
            // if (!task_executor_person_id && task_executor_name) {
            //     reqData.task_executor_name = task_executor_name ? task_executor_name : null
            // }

            AddProtocol(reqData, index)

            setProtocolData(initProtocolData)
            setShowFirstForm(false)
            setTimeout(()=>{
                scrollToBottom();
            },500);
        }
    }

    const addProtocolItem = (data, index) => {
        AddProtocol(data, index)
    }

    function onPositionChange({items}) {
        let order = items?.map((i, index) => {
            return {
                number: index + 1,
                id: i.protocol.id
            }
        })

        UpdateProtocolPosition(videoId, {order})
    }

    const convertTime = (price) => {
        let arr = price.split('')
        let newArr = []

        for (let i = 0; i < arr.length; i++) {
            if ((i + 1) % 3 === 0) {
                newArr.push(':')
            } else {
                newArr.push(arr[i])
            }
        }

        return newArr.join('')
    }

    const changePersonId = (person) => {
        setProtocolData({
            ...protocolData,
            person_id: person.id,
            author_name: getAuthorName(person, null, i18n.language)
        })
        setError({
            ...error,
            person_id: false
        })
    }

    const changeExecutorPersonId = (person) => {
        setProtocolData({
            ...protocolData,
            task_executor_person_id: person.id,
            task_executor_name: `${person.first_name ? person.first_name : ''} ${person.last_name ? person.last_name : ''}`
        })
        setError({
            ...error,
            task_executor_person_id: false,
        })
    }

    const openFirstForm = () => {
        setShowFirstForm(true)
        setProtocolData({
            ...protocolData,
            person_id: profileInfo?.attached_person?.id || undefined,
            time: getTime(videoPlayerCurrentTime) ? getTime(videoPlayerCurrentTime) : ''
        })
    }

    const clearTime = () => {
        setProtocolData({
            ...protocolData,
            time: ''
        })
    }

    const closeEditing = () => {
        setProtocolData({
            ...initProtocolData,
            author_name: `${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`
        })
        setShowFirstForm(false)
    }

    const items = [];
    !!protocolList?.length && protocolList.sort((a, b) => a.number - b.number).forEach((item, index) => {
        const current = texts.find(i => i.text === item?.text)
        items.push({
            id: index,
            index: index,
            protocol: {
                ...item,
                end_time: Number(current?.end_time) ? Number(current?.end_time) : Number(item?.start_time) + 10
            }
        })
    });
    const [dndCharacters, setDndCharacters] = useState(items);
    const [visibleEmptyEditor, setVisibleEmptyEditor] = useState(false);

    useEffect(()=>{
        setDndCharacters(items)
    },[protocolList])

    useEffect(()=>{
        if(page === 1){
            setTimeout(()=>{
                setVisibleEmptyEditor(true)
            },400)
        }
    },[page])

    const renderItem = ({item, index}) => {
        const currentItem = Number(item?.protocol?.start_time) === Number(videoPlayerCurrentTime) && Number(currentVideoId) === Number(item?.protocol?.start_time)

        return <ProtocolItem
            moveRow={moveRow}
            key={item.protocol?.id}
                             protocol={item?.protocol}
                             videoId={videoId}
                             persons={persons}
                             authorName={`${userProfile?.first_name || ''} ${userProfile?.last_name || ''}`}
                             company={company}
                             videoPersons={videoPersons}
                             changeVideoTime={changeVideoTime}
                             showFirstForm={showFirstForm}
                             setShowFirstForm={setShowFirstForm}
                             videoPlayerCurrentTime={videoPlayerCurrentTime}
                             getTime={getTime}
                             token={token}
                             prevProtocolId={protocolList?.[index - 1]?.id}
                             protocolIndex={index}
                             GetLink={GetLink}
                             DeleteProtocol={DeleteProtocol}
                             UpdateProtocol={UpdateProtocol}
                             AddProtocolHighlight={props.AddProtocolHighlight}
                             DeleteProtocolHighlight={props.DeleteProtocolHighlight}
                             currentItem={currentItem}
                             addProtocolItem={addProtocolItem}
                             MarkVideo={MarkVideo}
                             texts={texts}
                             handleScroll={handleScroll}
                             setCopied={setCopied}
                             GetProtocol={props.GetProtocol}
                             personsList={props.personsList}
                            openFirstForm={openFirstForm}
                            items={items}
        />
    };

    const moveRow = useCallback((dragIndex, hoverIndex) => {
        setDndCharacters((prevCharacters) =>{
                const order = update(prevCharacters, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCharacters[dragIndex]]
                ]
            })
                let updateOrder = order?.map((i, index) => {
                    return {
                        number: index + 1,
                        id: i.protocol.id
                    }
                })
                UpdateProtocolPosition(videoId, {order:updateOrder})
                return order;
        }

        );
    }, []);

    const renderDndCharacterCards = () =>
        dndCharacters.map((dndCharacter, index) => renderItem({item:dndCharacter,index})
    );

    const isEditProtocolId = useSelector((state) => state.protocol?.isEditProtocolId);

    return <div className="protocol">
        <DndProvider backend={HTML5Backend}>
        <div className="protocol__header">
            <Text size="f18" font="medium">{t('protocol_title')}</Text>
            {token && <>
                <Popover
                    arrow={false}
                    trigger='click'
                    placement="bottomLeft"
                    overlayClassName='popover_edit-menu'
                    content={
                        <>
                            <div
                                className={'popover_edit-menu--item delete'}
                                onClick={() => {
                                    GetLink({
                                        video_id: videoId,
                                    })
                                        .then((res) => {
                                            navigator.clipboard.writeText(res.text);
                                            setCopied(true)
                                        })
                                }}
                            >
                                <ShareIcon className={''}/> {t('share_video_and_protocol')}
                            </div>
                        </>
                    }
                >
                    <div className="popover_edit-menu--settings" onClick={(e) => e.stopPropagation()}>
                        <EllipsisIcon/>
                    </div>
                </Popover>
            </>}
        </div>
            {(dndCharacters.length <= 0 && visibleEmptyEditor) ?
                        <ProtocolItemBlock
                            text={''}
                            videoTime={videoPlayerCurrentTime}
                            onEdit={openFirstForm}
                            onCloseEdit={closeEditing}
                            isEdit={true}
                            onSaveText={(text)=>addVideoProtocol(text, 0)}
                            items={items}
                        />
                : null}
        {(!showFirstForm && items.length === 0 && token) ?
          <div className="info-block" onClick={(e)=>{
              if(!visibleEmptyEditor){
                  e.stopPropagation();
                  openFirstForm()
              }
          }}>
              <button className="voice-block--btn">
                  <VoiceMessage/>
              </button>
              <Text font="liteBold" size="f14" color="#9898A6">
                  {t('favourites_voice_text_1')}
              </Text>
          </div>
          : null}
        <div
          className="protocol-list" ref={protocolContent}
          onWheel={(e)=>e.stopPropagation()}
        >
                {renderDndCharacterCards()}
            {showFirstForm ?
                <ProtocolItemBlock
                    text={''}
                    videoTime={videoPlayerCurrentTime}
                    onEdit={openFirstForm}
                    onCloseEdit={closeEditing}
                    isEdit={true}
                    onSaveText={(text)=>addVideoProtocol(text, dndCharacters.length-1)}
                    items={items}
                />
                : null}

                <div
                    style={{height: '300px', cursor: "text"}}
                    onClick={(e)=>{
                        if(!(isEditProtocolId || showFirstForm) && isEditProtocolId !== null && !(!showFirstForm && items.length === 0)){
                            e.stopPropagation();
                            openFirstForm()
                        }
                    }}
                />
        </div>
    </DndProvider>
</div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['token', 'userProfile', 'personsList'])
};

const mapDispatchToProps = {
    AddProtocolHighlight,
    DeleteProtocolHighlight,
    UpdateProtocolPosition,
    GetProtocol
}
export default connect(mapStateToProps, mapDispatchToProps)(Protocol);
