import React, {useState} from 'react';
import {LayoutContainer} from "../../modules/Layout";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import './IntegrationsPage.scss';
import {Text} from "../../modules";
import ServicesList from "./components/ServicesList/ServicesList";
import CalendarInfoBlock from "./components/CalendarInfoBlock/CalendarInfoBlock";
import {SERVICE_TYPES} from "./helpers";
import SuccessModalProvider from "./components/SuccessModal/SuccessModalProvider";
import {GoogleOAuthProvider} from "@react-oauth/google";
import TelegramBlock from "./components/TelegramBlock/TelegramBlock";
import ChatBlock from "../../shared/chat/ChatBlock";
import {isCheckAuth} from "../../utils/helperFunctions";


const IntegrationsPage = () => {
	const {t} = useTranslation();
	const isFullWidth = useSelector(state => state.utils.isFullWidth);
	const [activeTab, setActiveTab] = React.useState(SERVICE_TYPES.GOOGLE);
	const isAuth = isCheckAuth();

	return (
		<LayoutContainer>
			<SuccessModalProvider>
					<div className={`main-container channels-wrap ${isFullWidth ? '' : 'full'}`}>
						<div className="integrations-block">
							<div className="integrations-block__header">
								<Text size="f20" font="medium">{t('integrations')}</Text>
							</div>
							<div className="channels-block__wrap">
								<ServicesList activeTab={activeTab} setActiveTab={setActiveTab}/>
								{[SERVICE_TYPES.GOOGLE, SERVICE_TYPES.OUTLOOK].includes(activeTab) &&
									<CalendarInfoBlock calendarType={activeTab}/>}
								{SERVICE_TYPES.TELEGRAM === activeTab && <TelegramBlock/>}
							</div>
						</div>
					</div>
				{isAuth ? <ChatBlock/> : null}
			</SuccessModalProvider>
		</LayoutContainer>
	)
};

export default IntegrationsPage;