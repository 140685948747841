import React, {forwardRef, useRef} from 'react';
import ProtocolEditor from "../ProtocolEditor/ProtocolEditor";
import './ProtocolItemBlock.scss';
import {useDispatch, useSelector} from "react-redux";
import * as Y from "yjs";
import {WebsocketProvider} from "y-websocket";
import {ToggleEditProtocolFlagService} from "../../../../redux/actions";
import {convertHttpsToWss} from "../../../../utils/helperFunctions";
import {SOCKET_FAVORITES_URL} from "../../../../redux/api";

const ProtocolItemBlock =  forwardRef((props, ref) =>  {
    const {text, protocol, isEdit, onCloseEdit, onChangeTypeShowForm, onSaveText,videoTime,updateClick,items,openFirstForm,onEdit} = props;
    const jwt = useSelector((state) => state.auth?.token);
    const providerRef = useRef(null);
    const ydoc = useRef(new Y.Doc()).current;
    const dispatch = useDispatch();
    const isAuth = useSelector(state => Boolean(state.auth?.token));

    const generateProvider = () => {
        if (!providerRef?.current && isAuth) {
            providerRef.current = new WebsocketProvider(
                SOCKET_FAVORITES_URL,
                `favorites_${protocol?.id}`,
                ydoc,
                {params: {jwt}}
            );
        }
        return providerRef
    }


    return (
        <div className="protocol-block" id={`protocol-block--${protocol?.id}`}>
            <ProtocolEditor
                ref={ref}
                text={text}
                onCloseEdit={() => {
                    if (!protocol?.id && onChangeTypeShowForm) {
                        onChangeTypeShowForm();
                    }
                    onCloseEdit();
                    dispatch(ToggleEditProtocolFlagService(undefined))
                }}
                items={items}
                isEdit={isEdit}
                protocol={protocol}
                onSaveText={onSaveText}
                providerRef={generateProvider()}
                ydoc={ydoc}
                onChangeTypeShowForm={onChangeTypeShowForm}
                videoTime={videoTime}
                updateClick={updateClick}
                onEdit={onEdit}
                openFirstForm={openFirstForm}
            />

        </div>
    );
});

export default ProtocolItemBlock;