import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import './channel-page.scss';
import {Breadcrumb} from "react-bootstrap";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
	ChangeHeaderTitle,
	GetVideos,
	GetPersons,
	DeleteChannel,
	GetChannelById, GetVideosService, ResetFiltersAction
} from "../../../redux/actions";
import {GetChannels} from "../../../redux/actions";
import {useTranslation} from "react-i18next";
import {CloseIcon, DeletingIcon, EditIcon, EditNewIcon, EllipsisIcon} from "../../../image";
import DropdownMenu from "../../../shared/features/dropdownMenu";
import ChannelsModal from "../../../shared/modals/channelModal/ChannelsModal";
import ChainsDropDown from "../../../shared/features/Chains/ChainsDropDown/ChainsDropDown";
import VideoBlock from "../../Dashboard/containers/Channels/VideoBlock/VideoBlock";
import {generateChannelsList} from "../../../shared/features/Chains/helpers";
import {getChains} from "../../../redux/actions/chainsActions";
import {hasPermission, scrollTop} from "../../../utils/helperFunctions";
import {Loader} from "../../../shared/features/Loader";
import {VIDEOS_CONSTS} from "../../../redux/constants";
import {Popover} from "antd";
import ChannelInfoDropDown from "../../../modules/Channel/ChannelInfoDropDown/ChannelInfoDropDown";
import FiltersAll from "../../../modules/Filters/components/FiltersAll/FiltersAll";
import {FILTER_SORT_PAGE_TYPES, getValueFilters} from "../../../modules/Filters/helpers";
import FiltersList from "../../../modules/Filters/components/FiltersList/FiltersList";
import {ApiStatus} from "../../../utils/constants";
import ChainList from "../../../modules/Chain/components/ChainList/ChainList";
import {ROLE_ITEMS} from "../../../utils/constants";
import CustomPagination from "../../../shared/features/Pagination";
import {ROUTERS_MAP} from "../../../utils/routes";
import {GetNotesService} from "../../../redux/actions/notesActions";
import {history} from "../../../utils/history";
import {objectToQueryString} from "../../../utils/api";
import {CONTENT_TYPE} from "../../Notes/helpers";
import ChannelForm from "../../../modules/Channel/ChannelForm/ChannelForm";


function ChannelPage(props) {
	const location = useLocation();
	const params = useParams();
	const {t} = useTranslation();
	const channels = useSelector(state => state.channels?.channels);
	const channelById = useSelector(state => state.channels?.channelById);
	const [isLoading, setIsLoading] = useState(false);
	const channelsUserCount = channelById?.users_count || 0;
	const chainsCount = channelById?.chains?.length || 0;
	const [showChannelModal, setShowChannelModal] = useState(false);
	const chainsList = channelById?.chains || [];
	const dispatch = useDispatch();
	const videosByChannelState = useSelector(state => state.videos?.videosByChannel);
	const videosChannel = videosByChannelState?.results || [];
	const channelsList = generateChannelsList(channels);
	const channelName = channels?.find(i => i?.id === Number(params?.channelId))?.name;
	const isFetchingVideos = useSelector(state => state.videos.isFetchingVideos);
	const allFilters = useSelector(state => state.filtersOrSort);
	const allFiltersValue = getValueFilters(allFilters, FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE);
	const filters = useSelector(state => state.filtersOrSort?.[FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE].filters?.selectedFilters || []);
	const [activePage, setActivePage] = useState(1);
	const notesListInfo = useSelector(state => state.notes.notesList);
	const notesListCount = notesListInfo.data?.count || 0;

	const getVideos = async (page) => {
		try {
			setIsLoading(true);
			const response = await dispatch(GetVideosService({
				channel_id: params?.channelId,
				limit: 20,
				offset: 20 * (page - 1),
				...allFiltersValue
			}, true));
			dispatch({
				type: VIDEOS_CONSTS.GET_VIDEOS_CHANNEL,
				payload: response
			});
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.warn(e)
		}
	}
	const getContent = async () => {
		try {
			await getVideos(1);
			if(params?.channelId) props.GetChannelById(params?.channelId)
			const title = t('menu_item_knowledge')
			await props.ChangeHeaderTitle(title)
		} catch (e) {
		}

	}

	useEffect(() => {
		dispatch(ResetFiltersAction({pageType: FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE}));
	}, []);

	useEffect(() => {
		if(videosByChannelState?.offset === 0) {
			setActivePage(1)
		}
	}, [videosByChannelState]);

	useEffect(() => {
		scrollTop();
		getContent();
		dispatch(GetNotesService({
			limit: 20,
			offset: 0,
			channels_ids: [params?.channelId]
		}));
		return () => {
			dispatch({
				type: VIDEOS_CONSTS.CLEAR_VIDEOS_CHANNEL,
				payload: {}
			});
			dispatch(ResetFiltersAction({
				pageType: FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE
			}))
		}
	}, [params?.channelId]);

	const changePage = async (number) => {
		scrollTop();
		setActivePage(number)
		setTimeout(async () => {
			await getVideos(number)
		}, 300)
	};

	function navigateToNotes() {
		history.push({
			pathname: `${ROUTERS_MAP.NOTES}`,
			search: objectToQueryString({channelsIds: [params?.channelId], contentType: CONTENT_TYPE.ACTIONS_ITEMS}),
			state: {
				routs: [
					{
						path: ROUTERS_MAP.CHANNEL,
						name: channelName
					}
				]
			}
		})
	}

	return (
		<div className={`main-container channels-container ${props.isFullWidth ? '' : 'full'}`}>
			<div className="channels-page">
				<>
					<div className="channels-page__wrap">
						<Breadcrumb>
							{
								(location?.state?.routs || []).map((item, index) => {
									return (
										<Breadcrumb.Item
											key={item?.name}
											active={item?.active}
											className={item?.active ? 'breadcrumb_name' : ''}
										>
											{item?.active ?
												<div className="channels-page__name">
													{item?.name}
												</div>
												:
												<Link
													to={{
														pathname: item?.path,
														state: {
															routs: location?.state?.routs.slice(0, index + 1)
														}
													}}
												>
													{item?.name}
												</Link>
											}
										</Breadcrumb.Item>)
								})
							}
							<Breadcrumb.Item active>
								<span>{channelName}</span>
							</Breadcrumb.Item>
						</Breadcrumb>
						<div className="channels-page__info">
							<Popover
								arrow={false}
								trigger='click'
								placement="bottomLeft"
								overlayClassName='popover_manage-chain'
								content={
									<ChannelInfoDropDown
										channelId={params?.channelId}
									/>
								}
							>
                               <span className="channels-info--text">
                                    {channelsUserCount} {t('participants')}
                            </span>
							</Popover>

							<Popover
								arrow={false}
								trigger='click'
								placement="rightTop"
								overlayClassName='popover_manage-chain'
								content={
									<ChainsDropDown
										chains={channelById?.chains || []}
										channel={channelById}
										channelStyle={channelsList?.[channelById?.id]}
									/>
								}
							>
                        <span className="channels-info--text">
                                &nbsp;&bull;&nbsp;{chainsCount} {t('Chains')}
                            </span>
							</Popover>
							{notesListCount > 0 ?
								<span className="channels-info--text" onClick={navigateToNotes}>
                                &nbsp;&bull;&nbsp;{notesListCount} {t('related_blocks')}
                            </span>
								:
								null
							}
						</div>
						{hasPermission(ROLE_ITEMS.CHANNELS_EDIT) ?
							<DropdownMenu
								closeAfterToggle={true}
								className="actions-menu"
								contentWidth={'176px'}
							>
								{hasPermission(ROLE_ITEMS.CHANNELS_EDIT) &&
									<div className={'menu-item'} onClick={(e) => {
										e.stopPropagation()
										// toggleUpdate()
										setShowChannelModal(true)
									}}>
										<EditIcon className={''}/> {t('button_edit')}
									</div>}
								{hasPermission(ROLE_ITEMS.CHANNELS_DELETE) &&
									<div className={'menu-item delete'}
									     onClick={() => props.DeleteChannel(channelName?.id)}>
										<DeletingIcon className={''}/> {t('button_delete')}
									</div>}
							</DropdownMenu>
							: null}
						{/*<DropdownMenu*/}
						{/*  className="chains-menu"*/}
						{/*>*/}
						{/*    <ChainsDropDown*/}
						{/*      chains={channelById?.chains || []}*/}
						{/*      channel={channelById}*/}
						{/*    />*/}
						{/*</DropdownMenu>*/}
						<FiltersAll pageType={FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE} className="filters-all"/>
					</div>
					<ChainList channel={channelById} channelsList={channelsList}/>
					<FiltersList pageType={FILTER_SORT_PAGE_TYPES.CHANNEL_PAGE}/>
					{(isFetchingVideos === ApiStatus.LOADING || isLoading) ?
						<div className="loader-wrap">
							<Loader siz={50}/>
						</div>
						:
						<div
							className={`channels__section-videos`}
						>
							{!!videosChannel?.length ? <>
									{
										videosChannel?.map((item) => {
											return (
												<VideoBlock key={item?.id}
												            badge={channelById}
												            channelsList={channelsList}
												            chainList={chainsList}
												            isPreview={true}
												            routs={[
													            {
														            path: '/dashboard/videos/review',
														            name: t('menu_item_knowledge')
													            },
													            {
														            path: `${ROUTERS_MAP.CHANNEL}/${params.channelId}`,
														            name: channelName
													            }
												            ]}
												            video={item}
												            isDetach={true}
												/>
											)
										})
									}
								</>
								:
								<>
									{filters.length ?
										<div className="empty-info">
											<span className="empty-info--text">{t('empty_list_videos_text_1')}</span>
											<span className="empty-info--text">{t('empty_list_videos_text_2')}</span>
										</div>
										:
										<div className={`channels__section-videos-empty`}>
											{t('empty_list')}
										</div>
									}
								</>
							}
						</div>
					}
				</>
				{videosByChannelState?.count > 20 &&
					<div className="channels-container__footer">
						<CustomPagination count={videosByChannelState?.count}
						                  activePage={activePage}
						                  listLength={20}
						                  changePage={changePage}/>
					</div>
				}
				{showChannelModal ?
					<ChannelsModal showModal={showChannelModal}
								   channelById={channelById}
								   onHide={()=>setShowChannelModal(false)}
					/>
				:null
				}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, [
		'isMobile',
		'channels',
		'videosList',
		'isFullWidth',
		'userRoles'
	])
};

const mapDispatchToProps = {
	GetVideos,
	GetChannels,
	ChangeHeaderTitle,
	GetPersons,
	DeleteChannel,
	getChains,
	GetChannelById
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPage);
