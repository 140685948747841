import {_urlServiceStatuses, _urlShare, _urlVideos, request} from "../api";
import {VIDEOS_CONSTS} from "../constants";
import {objectToQueryString} from "../../utils/api";
import {ApiStatus} from "../../utils/constants";

export const UpdateVideoChannel = (id, data, chains) => {
    const requestData = {
        url: `${_urlVideos}/${id}/change_channels`,
        token: true,
        method: "PATCH",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VIDEOS_CONSTS.UPDATE_VIDEO_CHANNEL,
                    payload: {id, channels: data.channels, chains}
                });
            })

    }
};

export const GetVideosService = (params, returnList) => {
    let requestData = {
        url: `${_urlVideos}${objectToQueryString(params)}`,
        method: "GET"
    };

    return dispatch => {

        return request(requestData)
          .then(async (res) => {
              const data = res && res.data;
              await dispatch({
                  type:VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
                  payload: ApiStatus.LOADING
              })
              await dispatch({
                  type: VIDEOS_CONSTS.GET_VIDEOS,
                  payload: data
              })
              await dispatch({
                  type:VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
                  payload: ApiStatus.SUCCESS
              });
              if(returnList){
                  return data
              }
          }).catch(async ()=>{
              await dispatch({
                  type:VIDEOS_CONSTS.CHANGE_API_STATUS_GET_VIDEOS,
                  payload: ApiStatus.ERROR
              })
          })
    };
};
export const GetStatusVideo = (id, uuid) => {
  let requestData = {
    url: `${_urlVideos}/${id}/status`,
    method: "GET",
  };
  uuid && (requestData.url = requestData.url + `?uuid=${uuid}`)
  return dispatch => {
    return request(requestData)
      .then(async (res) => {
        return res?.data;
      })
  };
};

export const GetVideosServiceReturnList = (params) => {
    let requestData = {
        url: `${_urlVideos}${objectToQueryString(params)}`,
        method: "GET"
    };

    return dispatch => {
        return request(requestData)
          .then(async (res) => {
              const data = res && res.data;

              return data
          }).catch(async ()=>{

          })
    };
};

export const GetVideos = ({
                              offset = 0,
                              limit = 10,
                              filters = [],
                              returnList = false,
                              manage = false
                          } = {}) => {
    let requestData = {
        url: `${_urlVideos}?offset=${offset}&limit=${limit}&manage=${manage}`,
        method: "POST",
        data: {
            params: {
                filters: JSON.stringify(filters)
            }
        }
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                if (!returnList) {
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEOS,
                        payload: data
                    })
                } else {
                    return data
                }
            })
    };
};

export const DeleteVideo = id => {
    const requestData = {
        url: `${_urlVideos}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VIDEOS_CONSTS.DELETE_VIDEOS,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const ReprocessVideo = id => {
    const requestData = {
        url: `${_urlVideos}/${id}/reprocess`,
        token: true,
        method: "POST",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: VIDEOS_CONSTS.REPROCESS_VIDEOS,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const GetVideoById = (id, uuid, returnData) => {
    let requestData = {
        url: `${_urlVideos}/${id}`,
        method: "GET",
    };
    uuid && (requestData.url = requestData.url + `?uuid=${uuid}`)
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEO_BY_ID,
                        payload: data
                    })
                    return res
            })
    };
};

export const ClearVideoList = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEOS
        })
    };
};

export const ClearVideoById = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEO_BY_ID
        })
    };
};

export const GetVideosSearch = (data, returnData,signal) => {
    let requestData = {
        url: `${_urlVideos}/search`,
        method: "POST",
        data,
        signal
    };
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const results = res && res.data;
                if (returnData) {
                    return results
                } else {
                    await dispatch({
                        type: VIDEOS_CONSTS.GET_VIDEOS_SEARCH,
                        payload: results
                    });
                    await dispatch({
                        type: VIDEOS_CONSTS.SET_SEARCH_TEXT,
                        payload: data
                    })
                }
            })
    };
};

export const ClearVideosSearch = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.CLEAR_VIDEOS_SEARCH
        })
    };
};

export const AddVideoDescription = (video_id, description) => {
    let requestData = {
        url: `${_urlVideos}/${video_id}/description`,
        method: "PATCH",
        data: {
            description
        }
    };

    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: VIDEOS_CONSTS.ADD_VIDEO_DESCRIPTION,
                    payload: {
                        video_id,
                        description
                    }
                })
            })
    };
};


export const CreateVideoUrl = (data) => {
    let requestData = {
        url: `${_urlShare}`,
        method: "POST",
        data,
    };

    return () => {
        return request(requestData)
            .then(async (res) => {
                return res.data
            })
    };
};


export const GetServiceStatuses = () => {
    let requestData = {
        url: `${_urlServiceStatuses}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;

                await dispatch({
                    type: VIDEOS_CONSTS.GET_SERVICE_STATUSES,
                    payload: data
                })
            })
    };
};

export const VideoUrlRecord = (data) => {
    let requestData = {
        url: `${_urlVideos}/record`,
        method: "POST",
        data,
    };

    return () => {
        return request(requestData)
            .then(async (res) => {
                // console.log('res', res.data);
                return res.data
            })
    };
};

export const MarkVideo = (data) => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.MARK_VIDEO,
            payload: data
        })
    };
};

// export const UnmarkVideo = (data) => {
//     return async dispatch => {
//         await dispatch({
//             type: VIDEOS_CONSTS.UNMARK_VIDEO,
//             payload: data
//         })
//     };
// };


export const ClearTextSearch = () => {
    return async dispatch => {
        await dispatch({
            type: VIDEOS_CONSTS.SET_SEARCH_TEXT,
            payload: ''
        })
    };
};
export const AddVideoToChain = (videoId, data) => {
    let requestData = {
        url: `${_urlVideos}/${videoId}/change_chains`,
        method: "PATCH",
        data: {
            chains: data
        }
    };

    return dispatch => {
        return request(requestData)
          .then(async (res) => {
              await dispatch({
                  type: VIDEOS_CONSTS.ADD_CHAIN_TO_VIDEO,
                  payload: {video_id: videoId, data}
              })
          })
    };
};

export const DeleteVideoToChain = (videoId, data) => {
    let requestData = {
        url: `${_urlVideos}/${videoId}/change_chains`,
        method: "PATCH",
        data: {
            chains: data
        }
    };

    return dispatch => {
        return request(requestData)
          .then(async (res) => {
              await dispatch({
                  type: VIDEOS_CONSTS.DELETE_CHAIN_TO_VIDEO,
                  payload: {video_id: videoId, data}
              })
          })
    };
};


export const ReprocessingVideoService = (id, data) => {
    let requestData = {
        url: `${_urlVideos}/${id}/reprocess_llm_response`,
        method: "POST",
        data
    };

    return () => {
        return request(requestData)
          .then(async (res) => {
              return res.data
          })
    };
};