import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Text} from "../../../../modules";
import {Button} from "../../../../modules/Button";
import {GoogleMeetIcon, JiraIcon, OutlookCalendarIcon, TelegramIcon, СonfluenceIcon} from "../../../../image";
import "./ServicesList.scss";
import {SERVICE_TYPES, SuccessModalContext} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {
	GetUserInfoGoogleService
} from "../../../../redux/actions";
import {googleLogout, GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {useCalendarActions} from "../../hooks";


const ServicesList = (props) => {
	const {activeTab, setActiveTab} = props;
	const {t} = useTranslation();
	const googleToken = useSelector(state => state.userCalendars?.googleToken);
	const googleUser = useSelector(state => state.userCalendars?.googleUserInfo);
	const outlookToken = useSelector(state => state.userCalendars.outlookInfo?.token?.access_token);
	const outlookInfo = useSelector(state => state.userCalendars.outlookInfo)
	const {handleOnSuccess,telegramLink} = useContext(SuccessModalContext);
	const dispatch = useDispatch()
	const userCalendars = useSelector(state => state.userCalendars);
	const {OutlookAuth, OutlookLogOut, GoogleLogOut} = useCalendarActions();

	const handleClickTelegramLinkBtn = () => {
		window.open(telegramLink, '_blank')
	}

	useEffect(() => {
		if(googleToken) {
			dispatch(GetUserInfoGoogleService(googleToken));
		}
	}, [googleToken])

	return (
		<div className="services">
			<div className="services__list">
				<div className="service">
					<Text size="f16" font="medium">{t('calendar_settings_calendar')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.GOOGLE ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.GOOGLE)}
					>
						<div className="service__item--container">
							<GoogleMeetIcon/>
							<div className="service__item--info">
								<Text size="f16" font="liteMedium">{t('calendar_settings_user_title')}</Text>
								{googleUser?.email ? <div className="service__item--info-text">
										<Text size="f14" font="book" color="#9898A6">{t('calendar_settings_user_name')} </Text>
										<Text size="f14"
											  font="book"
											  color="#212121">{googleUser?.email}
										</Text>
									</div>
									: null
								}
							</div>
							{googleToken ?
								<Button
									text={t('calendar_settings_disconnect')}
									variant="ghost"
									size="xs"
									color="red"
									onClick={GoogleLogOut}
								/>
								:
								<>
									{userCalendars?.clientId ?
										<GoogleOAuthProvider clientId={userCalendars?.clientId}>
											<GoogleButton
												handleOnSuccess={handleOnSuccess}
												t={t}
											/>
										</GoogleOAuthProvider>
										: null}
								</>
							}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('google_calendar_description')}
						</Text>
					</div>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.OUTLOOK ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.OUTLOOK)}
					>
						<div className="service__item--container">
						<OutlookCalendarIcon/>
						<div className="service__item--info">
							<Text size="f16" font="liteMedium">Microsoft Outlook</Text>
							{(outlookInfo?.outlook_email && outlookToken) ?
								<div className="service__item--info-text">
									<Text size="f14" font="book" color="#9898A6">{t('calendar_settings_user_name')} </Text>
									<Text size="f14" font="book" color="#212121">{outlookInfo?.outlook_email}</Text>
								</div>
								: null}
						</div>
						{outlookToken ?
							<Button
								text={t('calendar_settings_disconnect')}
								variant="ghost"
								size="xs"
								color="red"
								onClick={OutlookLogOut}
							/>
							:
							<Button
								text={t('connect')}
								variant="ghost"
								size="xs"
								onClick={OutlookAuth}
							/>
						}
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('outlook_calendar_description')}
						</Text>
					</div>
				</div>

				<div className="service">
					<Text size="f16" font="medium">{t('chat_and_bot')}</Text>
					<div
						className={`service__item ${activeTab === SERVICE_TYPES.TELEGRAM ? 'active' : ''}`}
						onClick={() => setActiveTab(SERVICE_TYPES.TELEGRAM)}
					>
						<div className="service__item--container">
							<TelegramIcon/>
							<Text size="f16" font="liteMedium">Telegram</Text>
							<Button
								text={t('connect')}
								variant="ghost"
								size="xs"
								onClick={handleClickTelegramLinkBtn}
							/>
						</div>
						<Text font="book" size="f12" color="#9898A6">
							{t('telegram_description')}
						</Text>
					</div>
				</div>
				<div className="service">
					<Text size="f16" font="medium">{t('soon')}</Text>
					<div className="service__item" >
						<div className="service__item--container" style={{justifyContent: "flex-start"}}>
							<СonfluenceIcon/>
							<Text size="f16" font="liteMedium" color="#828282">Confluence</Text>
						</div>
					</div>
					<div className="service__item">
						<div className="service__item--container" style={{justifyContent: "flex-start"}}>
							<JiraIcon/>
							<Text size="f16" font="liteMedium" color="#828282">Jira</Text>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function GoogleButton({handleOnSuccess, t}) {
	const login = useGoogleLogin({
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/calendar',
		onSuccess: async (codeResponse) => {
			handleOnSuccess(codeResponse)
		},
		onError: errorResponse => console.log(errorResponse),
	});
	return (
		<Button
			onClick={login}
			text={t('connect')}
			variant="ghost"
			size="xs"
		/>
	);
}

export default ServicesList;